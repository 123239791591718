import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import Avatar from '@shared/ui/display/Avatar';
import { ReactComponent as BinanceCircleIcon } from '@icons/exchanges/binance-circle.svg';
import { ContainerColumn, ContainerRow } from '@components/styled';
import { Body, BodyLargeSemiBold } from '@components/styled/Typography';
import Icon from '@shared/ui/icons/Icon';
import withTextEllipsis from '@shared/ui/tooltips/withTextEllipsis';
import VerificationIcon from '@shared/ui/icons/VerificationIcon';
import { WithSkeleton } from '@shared/ui/progress-loaders/Skeleton';
import PortfolioSubscribers from '../PortfolioSubscribers';
import PortfolioSubscriptionPrice from '../PortfolioSubscriptionPrice';
const PortfolioHeadlineContainer = styled(ContainerRow, { shouldForwardProp: propName => propName !== 'showAvatar' })((props) => ({
    width: 'auto',
    height: 'auto',
    alignItems: 'center',
    gap: props.theme.spacing_sizes.m,
    padding: props.showAvatar ? 0 : '1px 0',
}));
const PortfolioNameContainer = styled(ContainerRow)(() => ({
    width: 'auto',
    height: 'auto',
    alignItems: 'center',
    gap: 4,
}));
const PortfolioName = styled(withTextEllipsis(BodyLargeSemiBold))(() => ({
    letterSpacing: '-0.108px',
    lineHeight: '22px', // min height that allows to fit simbols like 'g' with 'overflow: hidden'
}));
// TODO: remove explicit with/height
const AvatarContainer = styled(ContainerColumn, { shouldForwardProp: propName => propName !== 'showAvatar' })(props => ({
    position: 'relative',
    width: props.showAvatar ? '63px' : 42,
    height: props.showAvatar ? '54px' : 42,
    flexShrink: 0,
    alignItems: 'end',
}));
const ExchangeIconBadge = styled.div((props) => ({
    display: 'inline-flex',
    position: props.showAvatar ? 'absolute' : 'static',
    top: props.showAvatar ? -3 : 0,
    left: 0,
    borderRadius: '70px',
    zIndex: 1,
    border: props.showAvatar ? '3px solid var(--White, #FFF)' : 'unset',
}));
const HeadlineContainer = styled(ContainerColumn)(() => ({
    width: 'auto',
    height: 'auto',
    gap: 4,
}));
const HeadlineDetails = styled(ContainerRow)((props) => ({
    height: 'auto',
    color: props.theme.palette.text.secondary,
    gap: props.theme.spacing_sizes.m,
}));
const PortfolioHeadline = ({ className = undefined, portfolio = {}, showAvatar = true, showVerificationIcon = true, isLoading = false, }) => {
    var _a, _b;
    const { t } = useTranslation();
    const tradeType = useMemo(() => {
        let type = '';
        if ((portfolio === null || portfolio === void 0 ? void 0 : portfolio.type) === 'SPOT') {
            type = t('portfolio.trade_types.spot');
        }
        else if (portfolio.type === 'MARGIN') {
            if (!(portfolio === null || portfolio === void 0 ? void 0 : portfolio.referral_asset)) {
                type = t('portfolio.trade_types.margin');
            }
            else if ((portfolio === null || portfolio === void 0 ? void 0 : portfolio.referral_asset) === 'coin') {
                type = t('portfolio.trade_types.coinm');
            }
            else if ((portfolio === null || portfolio === void 0 ? void 0 : portfolio.referral_asset) === 'usdt') {
                type = t('portfolio.trade_types.usdtm');
            }
        }
        return type.toUpperCase();
    }, [portfolio === null || portfolio === void 0 ? void 0 : portfolio.type, portfolio === null || portfolio === void 0 ? void 0 : portfolio.referral_asset, t]);
    return (_jsxs(PortfolioHeadlineContainer, { className: className, showAvatar: showAvatar, children: [_jsxs(AvatarContainer, { showAvatar: showAvatar, children: [_jsx(ExchangeIconBadge, { showAvatar: showAvatar, children: _jsx(WithSkeleton, { variant: 'circular', size: showAvatar ? 24 : 42, isLoading: isLoading, children: _jsx(Icon, { size: showAvatar ? 24 : 42, IconComponent: BinanceCircleIcon, keepOriginalColor: true }) }) }), showAvatar &&
                        (_jsx(WithSkeleton, { isLoading: isLoading, size: 54, variant: 'circular', children: _jsx(Avatar, { variant: 'img', size: 54, imgUrl: portfolio.logo }) }))] }), _jsxs(HeadlineContainer, { children: [_jsx(WithSkeleton, { width: 179, height: 22, isLoading: isLoading, children: _jsxs(PortfolioNameContainer, { children: [_jsx(PortfolioName, { tooltipText: (portfolio === null || portfolio === void 0 ? void 0 : portfolio.portfolioName) || '', children: (portfolio === null || portfolio === void 0 ? void 0 : portfolio.portfolioName) || '' }), showVerificationIcon && (_jsx(VerificationIcon, { size: 16, verified: !!((_a = portfolio === null || portfolio === void 0 ? void 0 : portfolio.profile) === null || _a === void 0 ? void 0 : _a.isVerifiedTrader) }))] }) }), _jsx(WithSkeleton, { width: 188, height: 25, isLoading: isLoading, children: _jsxs(HeadlineDetails, { children: [_jsx(PortfolioSubscribers, { followersCount: ((_b = portfolio === null || portfolio === void 0 ? void 0 : portfolio.followers) === null || _b === void 0 ? void 0 : _b.count) || 0, userQueuePosition: portfolio.user_subscription_summary ?
                                        portfolio.user_subscription_summary.queue_position :
                                        undefined }), _jsx(PortfolioSubscriptionPrice, { price: portfolio.subscriptionPrice }), _jsx(Body, { children: tradeType })] }) })] })] }));
};
export default PortfolioHeadline;
